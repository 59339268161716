'use client';
import { useEnterPhonePin } from './model/use-enter-phone-pin';
import { COUNTRY } from 'shared/types/country';
import { EnterPhonePinFormRu } from './ui/enter-phone-pin-ru';
import { EnterPhonePinFormRo } from './ui/enter-phone-pin-ro';
import { EnterPhonePinFormBg } from './ui/enter-phone-pin-bg';

export const EnterPhonePin: React.FC = () => {
  const { country } = useEnterPhonePin();

  if (country === COUNTRY.ru) {
    return <EnterPhonePinFormRu />;
  }

  if (country === COUNTRY.ro) {
    return <EnterPhonePinFormRo />;
  }

  if (country === COUNTRY.bg) {
    return <EnterPhonePinFormBg />;
  }

  return null;
};

'use client';
import React from 'react';

import { AuthLayout, LayoutInner } from 'shared/ui/layout';
import Box from '@mui/material/Box';
import Header from 'widgets/header';
import Form from 'shared/ui/form';
import { InputPinCode } from 'shared/ui/input-pin-code/input-pin-code';
import { useTranslation } from 'react-i18next';
import { AuthDialog } from 'entities/dialog';
import { useEnterPhonePinFormBg } from '../model/use-enter-phone-pin-bg';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import InputPhone from 'shared/ui/input-phone';

export const EnterPhonePinFormBg: React.FC = () => {
  const { t } = useTranslation();
  const { formik, isSubmitDisabled, error, setError, onForgotPasswordClick } =
    useEnterPhonePinFormBg();
  const isDesktop = useMediaQuery('(min-width:960px)');

  return (
    <form onSubmit={formik.handleSubmit}>
      <AuthLayout>
        <Box>
          <Header
            title={t('sign-in.title')}
            // onDemo={() => {
            //   console.log('click demo');
            // }}
          />
          <Box
            pt={3}
            sx={{
              textAlign: 'center',
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: '500',
            }}
          >
            {t('sign-in.subtitle')}
          </Box>
          <LayoutInner>
            <Box
              pt={2}
              sx={{
                display: 'grid',
                gap: 2.5,
              }}
            >
              <InputPhone
                name="phone"
                mask={'+35\\9 (999) 999999'}
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && !!formik.errors.phone}
                helperText={formik.errors.phone}
              />
              <InputPinCode name="pin" value={formik.values.pin} onChange={formik.handleChange} />
              <AuthDialog.AuthenticationFailed open={!!error} onOk={() => setError(null)} />
            </Box>
          </LayoutInner>
          <Box mt={4}>
            <Form.ForgotPasswordButton onClick={onForgotPasswordClick} />
          </Box>
        </Box>
        <Form.ProgressSubmit progress={0} theme={'primary'} disabled={isSubmitDisabled}>
          {t('sign-in.submit')}
        </Form.ProgressSubmit>
        {isDesktop && <Form.SignUpButton />}
      </AuthLayout>
    </form>
  );
};

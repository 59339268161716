import { useState } from 'react';

export enum SignInSteps {
  enterPhone = 'enterPhone',
}

export const useSignIn = () => {
  const [currStep, setCurrStep] = useState<SignInSteps>(SignInSteps.enterPhone);

  return { currStep, setCurrStep };
};

'use client';
import React from 'react';

import { AuthLayout, LayoutInner } from 'shared/ui/layout';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Header from 'widgets/header';
import Form from 'shared/ui/form';
import { InputPinCode } from 'shared/ui/input-pin-code/input-pin-code';
import { useTranslation } from 'react-i18next';
import { AuthDialog } from 'entities/dialog';
import { useEnterPhonePinFormRo } from '../model/use-enter-phone-pin-ro';
import useMediaQuery from '@mui/material/useMediaQuery';

export const EnterPhonePinFormRo: React.FC = () => {
  const { t } = useTranslation();
  const { formik, isSubmitDisabled, error, setError, onForgotPasswordClick } =
    useEnterPhonePinFormRo();
  const isDesktop = useMediaQuery('(min-width:960px)');

  return (
    <form onSubmit={formik.handleSubmit}>
      <AuthLayout>
        <Box>
          <Header
            title={t('sign-in.title')}
            // onDemo={() => {
            //   console.log('click demo');
            // }}
          />
          <Box
            pt={3}
            sx={{
              textAlign: 'center',
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: '500',
            }}
          >
            {t('sign-in.subtitle')}
          </Box>
          <LayoutInner>
            <Box
              pt={2}
              sx={{
                display: 'grid',
                gap: 2.5,
              }}
            >
              <TextField
                name="contact"
                label={t('input-phone-or-email.label')}
                variant="standard"
                fullWidth
                value={formik.values.contact}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.contact && !!formik.errors.contact}
                helperText={formik.errors.contact}
                inputProps={{
                  style: {
                    fontVariant: 'tabular-nums',
                  },
                }}
              />
              <InputPinCode name="pin" value={formik.values.pin} onChange={formik.handleChange} />
              <AuthDialog.AuthenticationFailed open={!!error} onOk={() => setError(null)} />
            </Box>
          </LayoutInner>
          <Box mt={4}>
            <Form.ForgotPasswordButton onClick={onForgotPasswordClick} />
          </Box>
        </Box>
        <Form.ProgressSubmit progress={0} theme={'primary'} disabled={isSubmitDisabled}>
          {t('sign-in.submit')}
        </Form.ProgressSubmit>
        {isDesktop && <Form.SignUpButton />}
      </AuthLayout>
    </form>
  );
};

import { InputAdornment, TextField } from '@mui/material';
import { ChangeEventHandler, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';

type Props = {
  label?: string;
  onChange?: ChangeEventHandler;
  value?: string;
  name?: string;
};

export const InputPinCode = ({ value, onChange, label, name }: Props) => {
  const { t } = useTranslation();
  const [showPin, setShowPin] = useState(false);
  return (
    <TextField
      value={value}
      onChange={onChange}
      name={name}
      label={label || t('input-pin-code.label')}
      variant="standard"
      fullWidth
      type={showPin ? 'text' : 'password'}
      inputProps={{
        maxLength: 4,
        inputMode: 'numeric',
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setShowPin(!showPin);
              }}
              onMouseDown={() => {
                setShowPin(!showPin);
              }}
            >
              {!showPin ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

'use client';
import React from 'react';
import { SignInSteps, useSignIn } from './model/use-sign-in';
import EnterPhonePin from 'features/enter-phone-pin';

export const SignIn: React.FC = () => {
  const { currStep } = useSignIn();

  if (currStep == SignInSteps.enterPhone) {
    return <EnterPhonePin />;
  }
  return null;
};
